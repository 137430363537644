<script setup>
const props = defineProps({
  search: {
    type: String,
    default: '',
  },
  tableName: {
    type: String,
    default: 'data',
  },
})
</script>

<template>
  <div
    v-if="!search"
    class="p-10"
  >
    <div class="p-6">
      <span class="text-2xl grid justify-items-center font-bold">No {{ props.tableName.toLowerCase() }}</span>
    </div>
    <div class="mb-8 text-center">
      <span>There are currently no results for this table</span>
    </div>
  </div>
  <div
    v-else
    class="p-10"
  >
    <div class="p-6">
      <span class="text-2xl grid justify-items-center font-bold">No matches found</span>
    </div>
    <div class="mb-8 text-center">
      <span>
        Sorry no results were found using
        <span class="font-bold">"{{ props.search }}"</span>
      </span>
    </div>
  </div>
</template>
